<template>
  <div class="p-grid mail-box">
    <div v-if="profiles.length !== 0 || loading" class="p-col-12">
      <view-switcher
        :layout="layout"
        :loading="loading"
        :length="profiles.length"
        @toggleView="toggleView"
      />
      <div>
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          @updateMails="removeFromMails"
        >
          <!-- deleted -->
          <template #deletedProfile="{}">
            <div class="list-slot-container">
              <div class="list-item p-text-center">
                <p class="for-text-list for-text-list-del p-p-0">
                  This profile is deleted
                </p>
              </div>
            </div>
          </template>
          <template #activeProfile="{ profile }">
            <!-- requested -->
            <div
              v-if="profile.data.attributes.status === 'requested'"
              class="list-slot-container"
            >
              <div class="list-item p-text-center for-text-list-req">
                <span>Requested</span>
              </div>

              <div class="list-item for-text-list-req">
                <Button
                  v-tooltip.top="'Decline'"
                  icon="fas fa-times"
                  class="p-button-rounded p-button-raised buttons"
                  @click="showRejectPopup(profile.data.attributes.id)"
                />
                <Button
                  v-tooltip.top="'Accept'"
                  icon="fas fa-check"
                  class="p-button-rounded p-button-raised buttons"
                  @click="
                    setInvitationStatus(
                      'accepted',
                      profile.data.attributes.id,
                      profile.data.attributes.user_name
                    )
                  "
                />
              </div>
            </div>
            <!--rejected -->
            <div
              v-else-if="profile.data.attributes.status === 'rejected'"
              class="list-slot-container"
            >
              <div class="list-item p-text-center for-text-list-rej">
                <p v-if="selectedContact !== profile.data.attributes.id">
                  Rejected
                </p>
              </div>
            </div>
            <!-- accepted -->
            <div
              v-if="
                profile.data.attributes.status === 'accepted' &&
                selectedContact !== profile.data.attributes.id
              "
              class="list-slot-container"
            >
              <div class="list-item p-text-center for-text-list-acc">
                <p>Accepted</p>
              </div>
              <div class="list-item p-text-center for-text-list-acc">
                <i
                  v-tooltip.top="'Display number'"
                  class="fas fa-phone-alt p-ml-1 mobile-list call-round"
                  @click="showMobileNumber(profile.data.attributes.id)"
                ></i>
              </div>
            </div>
            <!-- displaying number -->
            <div
              v-if="
                profile.data.attributes.status === 'accepted' &&
                showNumber &&
                selectedContact === profile.data.attributes.id
              "
              class="list-item mob-num-trans-list"
            >
              <div class="item-itself">
                <i class="fas fa-phone-alt p-ml-1 p-mr-2"></i>
                <p
                  v-tooltip.top="
                    clipBoard ? 'Click to copy' : 'Copied to clipboard'
                  "
                  class="p-my-0 p-mx-1"
                  @click="
                    copyToClipboard(profile.data.attributes.user_contact_number)
                  "
                >
                  {{ profile.data.attributes.user_contact_number }}
                </p>
              </div>
            </div>
          </template>
        </list-view>
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
        >
          <template #default="{ profile }">
            <div
              v-if="!profile.data.attributes.user_is_active"
              class="p-d-flex p-jc-between footer-content"
            >
              <div class="p-d-flex p-jc-center pad">
                <p class="for-text-del">This profile is deleted</p>
              </div>
            </div>
            <div v-else-if="profile.data.attributes.status === 'requested'">
              <div
                class="
                  p-d-flex p-jc-between p-ai-center
                  footer-content
                  pad
                  p-p-1 p-pl-2
                "
              >
                <p class="for-text-req p-my-0">Requested</p>
                <div class="p-d-flex p-jc-end p-ml-auto p-ai-center">
                  <Button
                    v-tooltip.top="'Decline'"
                    icon="fas fa-times"
                    class="p-button-rounded p-button-raised buttons p-mr-2"
                    @click="showRejectPopup(profile.data.attributes.id)"
                  />
                  <Button
                    v-tooltip.top="'Accept'"
                    icon="fas fa-check"
                    class="p-button-rounded p-button-raised buttons p-mr-1"
                    @click="
                      setInvitationStatus(
                        'accepted',
                        profile.data.attributes.id,
                        profile.data.attributes.user_name
                      )
                    "
                  />
                </div>
              </div>
            </div>

            <div v-else-if="profile.data.attributes.status === 'accepted'">
              <div class="footer-content">
                <div class="p-d-flex p-jc-between p-ai-center call-align">
                  <p
                    v-if="selectedContact !== profile.data.attributes.id"
                    class="p-text-right for-text-acc p-my-0 p-ml-2 pad"
                  >
                    Accepted
                  </p>
                  <div
                    :class="
                      showNumber &&
                      selectedContact === profile.data.attributes.id
                        ? 'p-grid p-d-flex p-jc-center p-ai-center mob-num-trans-grid'
                        : null
                    "
                  >
                    <i
                      v-if="selectedContact !== profile.data.attributes.id"
                      v-tooltip.top="
                        windowWidth ? 'Display number' : { disabled: true }
                      "
                      class="fas fa-phone-alt p-mr-2 mobile-list"
                      @click="showMobileNumber(profile.data.attributes.id)"
                    />
                    <div
                      v-if="
                        showNumber &&
                        selectedContact === profile.data.attributes.id
                      "
                      class="p-d-flex p-jc-between p-p-0 p-ai-center mobile-num"
                    >
                      <i class="fas fa-phone-alt p-ml-1 p-mr-3"></i>
                      <p
                        v-tooltip.top="
                          clipBoard ? 'Click to copy' : 'Copied to clipboard'
                        "
                        class="p-p-0 p-my-0 p-mx-1"
                        @click="
                          copyToClipboard(
                            profile.data.attributes.user_contact_number
                          )
                        "
                      >
                        {{ profile.data.attributes.user_contact_number }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="profile.data.attributes.status === 'rejected'">
              <div class="footer-content">
                <div class="p-d-flex p-jc-center p-ai-center pad">
                  <p
                    v-if="selectedContact !== profile.data.attributes.id"
                    class="p-text-left for-text-del p-my-0"
                  >
                    Rejected
                  </p>
                </div>
              </div>
            </div>
          </template>
        </grid-view>
      </div>
    </div>
    <div v-else class="p-col-12">
      <empty-mails
        :empty-text="'There is no pending invitations in your list.'"
        :img="'/received.png'"
      />
    </div>
    <div v-if="showNumber" class="full-window" @click="hideMobleNumber" />
    <Dialog
      v-model:visible="showRejectDialog"
      :modal="true"
      :draggable="false"
      header="Confirmation"
      :dismissable-mask="true"
      class="reject-dialog"
    >
      <div class="p-d-flex p-flex-column reject-user-dialog">
        <p>Are you sure you want to reject?</p>
        <div class="p-d-flex p-ai-center">
          <Checkbox
            id="block_user_check"
            v-model="blockCheckBox"
            :binary="true"
          />
          <label for="block_user_check" class="p-ml-2">Block as well</label>
        </div>
      </div>
      <template #footer>
        <Button
          label="Yes"
          class="p-mr-5 p-button-outlined"
          @click="setInvitationStatus('rejected', rejectId)"
        /><Button label="No" autofocus @click="hideRejectPopup()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import '@/assets/ListAndGridView.scss';
import EmptyMails from '@/components/EmptyMails.vue';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [mailboxMixin],
  data() {
    return {
      clipBoard: true,
      adButton: true,
      reqUrl: 'interests/show_received_interests',
      showRejectDialog: false,
      rejectId: 0,
      blockCheckBox: false,
      androidTabletView: false,
    };
  },
  mounted() {
    this.switchView();
    window.addEventListener('resize', this.switchView);
  },
  unmounted() {
    window.removeEventListener('resize', this.switchView);
  },
  methods: {
    switchView() {
      this.androidTabletView = window.innerWidth > this.$androidTabletView;
    },
    hideRejectPopup() {
      this.rejectId = 0;
      this.showRejectDialog = false;
    },
    showRejectPopup(id) {
      this.rejectId = id;
      this.showRejectDialog = true;
    },
    setInvitationStatus(status, id, name) {
      let that = this;
      this.$http
        .patch(`/interests/${id}/update_status`, {
          interest: { status: status },
          block_user: this.blockCheckBox,
        })
        .then((res) => {
          let obj = res.data.data;
          that.profiles[
            that.profiles.indexOf(
              that.profiles.filter((item) => item.id === obj.id)[0]
            )
          ] = obj;
          if (status === 'accepted') {
            that.$moshaToast(
              name + ' should be getting your phone number in the phone icon',
              { type: 'info', showIcon: true }
            );
          } else {
            that.$moshaToast(
              `Successfully ${
                this.blockCheckBox
                  ? 'rejected the invitation and blocked the user'
                  : 'rejected the invitation sent'
              } .`,
              {
                type: 'success',
                showIcon: true,
              }
            );
          }
          this.showRejectDialog = false;
        })
        .catch(() => {
          that.$moshaToast('success', {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
        });
    },
  },
};
</script>
<style lang="scss">
.reject-user-dialog {
  width: 35rem;
  max-width: 90%;
}
.reject-dialog {
  .p-dialog-content {
    padding-bottom: 0.5rem !important;
  }
}
</style>
<style scoped lang="scss">
.list-slot-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  .list-item {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
.full-width {
  width: 100%;
}
.mobile-num {
  white-space: nowrap;
  overflow: hidden;
}
@media only screen and (max-width: $tabletView) {
  .p-button-outlined {
    margin-bottom: 0.15rem;
  }

  .list-slot-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .list-item {
      width: 100%;
      align-items: center;
      justify-content: center;
      .buttons {
        margin: 0.2rem 0.5rem;
      }
    }
  }
}
</style>
